<template>
    <v-container>
        <base-material-card icon="mdi-currency-usd" title="Lista" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Nombre" v-model="lista.nombre" :error-messages="errors.nombre" class="required">
                            </v-text-field>
                            <v-text-field label="Código" v-model="lista.codigo" :error-messages="errors.codigo" class="required">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="saveLista()" :loading="loading">Guardar</v-btn>
                    <v-btn @click="$router.push({name: 'Lista'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    const MODEL = {
        nombre: null,
        codigo: null
    }
    export default {
        data: () => ({
            lista: {},
            errors: {},
            loading: false
        }),
        mounted() {
            if (this.$route.params.id) {
                this.loadLista()
            }
        },
        methods: {
            loadLista() {
                this.$http.get(`/listas/${this.$route.params.id}`).then(response => {
                    this.lista = response.data
                })
            },
            saveLista() {
                this.errors = {}
                this.loading = true
                if (this.$route.params.id) {
                    this.updateLista()
                    return
                }
                this.createLista()
            },
            createLista() {
                this.$http.post('/listas', this.lista).then(response => {
                    this.$router.push({ name: 'Lista' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            updateLista() {
                this.$http.post(`/listas/${this.$route.params.id}`, this.toFormData(this.lista, 'PUT')).then(response => {
                    this.$router.push({ name: 'Lista' })
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            toFormData(object, method) {
                let formData = new FormData

                if (method) {
                    formData.append('_method', method)
                }

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        const element = object[key];
                    }
                    if (object[key]) {
                        if (Array.isArray(object[key])) {
                            object[key].forEach(subobject => {
                                formData.append(`${key}[]`, subobject)
                            })
                        } else {
                            formData.append(key, object[key])
                        }
                    }
                }
                return formData
            }
        }
    }
</script>